import * as React from "react"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="HOME" />
    <Hero />
  </Layout>
)

export default IndexPage
