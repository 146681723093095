import React, { useState } from "react"

import profileImage from "../images/jalal.png"

function Hero() {
  const [isOn, setIson] = useState(false)

  const buttonClicked = () => {
    setIson(!isOn)
  }

  return (
    <section className={`section section--large section--dark${isOn ? " section--inverted" : ""}`}>
      <div className="hero">
        <h1 className="hero__title">ZAROUIL.DEV</h1>
        <h2 className="hero__subtitle">
          web development / web design / shopify
        </h2>
        <a className="hero__link" href="mailto:jalal@zarouil.dev">
          <h3 className="hero__email">jalal@zarouil.dev</h3>
        </a>
        <span onClick={buttonClicked}>
          <img src={profileImage} alt="" className={`hero__image${isOn ? " hero__image--inverted" : ""}`} />
        </span>
      </div>
    </section>
  )
}

export default Hero
